import React from "react";
import NavBarr from "./NavBarr";
import styled from "styled-components";
import Footer from "./Footer";
// import styled from "styled-components";
// import { PriButton, PriHeader, TextHead } from "../Global";
// import sec1 from "../Images/sec1.png";
// import sec11 from "../Images/sec11.png";
// import sec111 from "../Images/sec111.png";
// import sec1111 from "../Images/sec1111.png";
// import { IoLogoWhatsapp } from "react-icons/io";
// import { FaPhoneAlt } from "react-icons/fa";
import { AiOutlineMessage } from "react-icons/ai";

function Layout({ children }) {
  // const [showIcons, setShowIcons] = useState(false);

  // const toggleIcons = () => {
  //   setShowIcons((prevState) => !prevState);
  // };
  return (
    <Mainbar>
      <div className="top_bar">
        <NavBarr />
      </div>
      <>
        <div className="main_body">{children}</div>

        <div>
          <Footer/>
        </div>
      </>
      <Root>
        {/* <div className="link_icon">
        <div className="hid_icon">
          <a
            href="https://wa.me/yourphonenumber"
            className="whatsapp-btn"
            style={{ display: showIcons ? "block" : "none" }}
          >
            <IoLogoWhatsapp />
          </a>

          <a
            href="tel:+1234567890"
            className="mobile-btn"
            style={{ display: showIcons ? "block" : "none" }}
          >
            <FaPhoneAlt />
          </a>
        </div>

        <div className="show_icon" onClick={toggleIcons}>
          <AiOutlineMessage />
        </div>
      </div> */}

        <div className="link_icon">
          <a href="https://wa.me/yourphonenumber" className="whatsapp-btn">
            {" "}
            <AiOutlineMessage />
          </a>
        </div>
      </Root>
    </Mainbar>
  );
}

export default Layout;
const Mainbar = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  min-height: 100vh;
  height: 100%;

  .top_bar {
    background: #ffffff;
    display: flex;
    height: 80px;
    width: 100%;
  }
  .main_body {
    height: 90%;
    width: 100%;
  }
`;

const Root = styled.section`
  /* .link_icon {
    .mobile-btn {
      position: fixed;
      bottom: 180px;
      right: 40px;
      z-index: 1000;

      svg {
        background: #03e78b;
        color: white;
        padding: 12px;
        border-radius: 51px;
        font-size: 60px;
      }
    }

    .whatsapp-btn {
      position: fixed;
      bottom: 110px;
      right: 40px;
      z-index: 1000;
      margin-top: 40px;
      transition-timing-function: ease;
      svg {
        background: #49e670;
        color: white;
        padding: 12px;
        border-radius: 51px;
        font-size: 60px;
      }
    }

    .show_icon {
      position: fixed;
      bottom: 40px;
      right: 40px;
      z-index: 1000;
      border-radius: 30px;
      cursor: pointer;
      &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
      }
      svg {
        background: linear-gradient(145.84deg, #150642 16.54%, #47193f 84.75%);
        color: white;
        padding: 12px;
        border-radius: 51px;
        font-size: 60px;
        &:hover {
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12),
            0 1px 2px rgba(0, 0, 0, 0.24);
        }
      }
    }
  } */


      .link_icon {


    .whatsapp-btn {
      position: fixed;
      bottom: 50px;
      right: 40px;
      z-index: 1000;
      margin-top: 40px;
      transition-timing-function: ease;
      svg {
        background: #49e670;
        color: white;
        padding: 9px;
        border-radius: 51px;
        font-size: 53px;
      }
    }


  } 
`;
