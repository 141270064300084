import React, { useEffect } from "react";
import styled from "styled-components";
import food11 from "../Images/food11.png";
import { PriButton, TextHead } from "../Global";
import Aos from "aos";

export default function Sec7(){
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="gourmet_content">
        <div className="content_div" data-aos="fade-left">
          <TextHead>
            At TimelessFilms.Media, we understand that food is not just
            nourishment – it's an experience. That's why we approach every
            project with creativity, passion, and a commitment to exceeding your
            expectations.{" "}
          </TextHead>
          <TextHead>
            Let us help you showcase your culinary creations in a way that
            leaves a lasting impression on your audience and keeps them coming
            back for more.
          </TextHead>
          <PriButton style={{ width: "200px", margin: "40px 0px" }}>
            Lets  Shoot
          </PriButton>
        </div>
        <img src={food11} alt="food11" data-aos="fade-right"/>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 490px;
      height: 632px;
    }
    .content_div {
      display: flex;
      gap: 10px;
      flex-direction: column;
      justify-content: center;
  
    }
  }

  @media (max-width: 567px) {
    padding: 0px 20px 20px;
    .gourmet_content {
    flex-wrap: wrap;
    margin-top: 0px;
    gap:0px;
    img {
    width: 100%;
}
  }
  .iqFyfv {
    font-size: 32px;
    line-height: unset;
}



  
  }

  @media (min-width: 567px) and (max-width: 992px){
  
    padding: 0px 20px;
    .gourmet_content {
    flex-wrap: wrap;
    margin-top: 0px;
    gap:0px;
    img {
    width: 100%;
}
  }
  .iqFyfv {
    font-size: 32px;
    line-height: unset;
}


  }
`;
