import React, { useEffect } from "react";
import styled from "styled-components";
import food5 from "../Images/food5.png";
import product6 from "../Images/product6.png";
import { Subhead, PriHeader } from "../Global";
import Aos from "aos";

export default function Sec2() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="logo_head" data-aos="fade-up">
        <img src={food5} alt="product5" />
        <PriHeader>Our Product Photoshoot Services Include</PriHeader>
      </div>

      <div className="gourmet_content">
        <img src={product6} alt="product6" data-aos="fade-right"/>
        <div className="content_div" data-aos="fade-left">
          <Subhead>High-Quality Photography</Subhead>
          <h2>
          Our experienced photographers specialize in capturing the unique features and qualities of your products, ensuring that every detail is beautifully showcased. From studio shots to lifestyle images, we tailor our photography to suit your brand aesthetic and marketing goals.
          </h2>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px   40px  32px;

  .logo_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap:10px;
      flex-direction: column;
    
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;
    .kucZgf {
      font-size: 45px;
      line-height: unset;
    }
    .gourmet_content {
      flex-wrap: wrap;
      gap: 30px;
      img {
        width: 100%;
      }

      .iqFyfv {
        font-size: 32px;
        line-height: unset;
      }
    }
    .beimEb {
    line-height: unset;
}
  }

  @media (min-width: 567px) and (max-width: 992px){
  
    padding: 40px 20px;
    .kucZgf {
      font-size: 45px;
      line-height: unset;
    }
    .gourmet_content {
      flex-wrap: wrap;
      gap: 30px;
      img {
        width: 100%;
        height: 100%;
      }

      .iqFyfv {
        font-size: 32px;
        line-height: unset;
      }
    }

  }
`;
