import React, { useEffect} from "react";
import styled from "styled-components";
import { PriButton, PriHeader, TextHead } from "../Global";
import sec1 from "../Images/sec1.png";
import sec11 from "../Images/sec11.png";
import sec111 from "../Images/sec111.png";
import sec1111 from "../Images/sec1111.png";
import Slider from "react-slick";
import AOS from "aos";
import "aos/dist/aos.css";


function Sec1() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  var settings = {
    dots:true,
    infinite:true,
    slidesToShow:3,
    slidesToScroll:3,
    initialSlide:0,
    autoplay:false,
    speed:2000,
    autoplaySpeed:2000,
    responsive:[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Root>
      <div className="text_div" data-aos="fade-up">
        {" "}
        <div className="main_heading" >
          <TextHead>
            Elevating memories to timeless masterpieces through stunning
            photography and captivating videography
          </TextHead>
        </div>
        <div style={{ padding: "10px 40px" }} className="head_cont_div">
          <PriHeader>– discover the magic at TimelessFilms.Media.</PriHeader>
          <br />
          <PriButton>Our services</PriButton>
        </div>
      </div>

    <div className="desk_div">
      <div className="img_div">
        <span className="d-flex flex-column">
          <img src={sec1} alt="img" style={{ marginBottom: "20px" }} />
          <div style={{ width: "336px" }} className="style_div">
            <i className="first_content">Weddings Photoshoot and Videography</i>
          </div>
        </span>
        <span className="d-flex flex-column">
          <img
            src={sec11}
            alt="img"
            style={{ height: "495px" }}
            className="second"
          />
          <div style={{ width: "330px" }} className="style_div">
            <i>Fashion Photography</i>
            <div className="line"></div>
          </div>
        </span>
        <span className="d-flex flex-column" >
          <img
            src={sec111}
            alt="img"
            style={{ height: "358px", marginBottom: "20px" }}
          />
          <div style={{ width: "300px" }} className="style_div">
            <i>Product Photography</i>
            <div className="line"></div>
          </div>
        </span>
        <span className="d-flex flex-column" >
          <img
            src={sec1111}
            alt="img"
            style={{ height: "294px", marginBottom: "20px" }}
          />

          <div style={{ width: "261px" }} className="style_div">
            <i>
              Food Photography <div className="line"></div>
            </i>
          </div>
        </span>
      </div>
    </div>

     <div className="mob_div">
      <div className="slider-container">
      <Slider {...settings}>

        <div>
        <span className="d-flex flex-column">
          <img src={sec1} alt="img" style={{ marginBottom: "20px" }} />
          <div style={{ width:"336px" }} className="style_div">
            <i className="first_content">Weddings Photoshoot and Videography</i>
          </div>
        </span>


        </div>

        <div>
        <span className="d-flex flex-column">
          <img
            src={sec11}
            alt="img"
            style={{ height: "495px" }}
            className="second"
          />
          <div style={{ width: "330px" }} className="style_div">
            <i>Fashion Photography</i>
            <div className="line"></div>
          </div>
        </span>

        </div>

        <div>
        <span className="d-flex flex-column">
          <img
            src={sec111}
            alt="img"
            style={{ height: "358px", marginBottom: "20px" }}
          />
          <div style={{ width: "300px" }} className="style_div">
            <i>Product Photography</i>
            <div className="line"></div>
          </div>
        </span>

        </div>

        <div>
        <span className="d-flex flex-column">
          <img
            src={sec1111}
            alt="img"
            style={{ height: "294px", marginBottom: "20px" }}
          />

          <div style={{ width: "261px" }} className="style_div">
            <i>
              Food Photography <div className="line"></div>
            </i>
          </div>
        </span>

        </div>





      </Slider>
    </div>



      </div> 
   

    </Root>
  );
}

export default Sec1;
const Root = styled.section`
  display:flex;
  justify-content:center;
  flex-direction:column;
  align-items:center;
  padding:0px 10px;
  margin:40px 0px;
  gap:24px;


  .text_div {
    margin: 10px 114px;
    text-align: center;
    padding: 10px 40px;

    .main_heading {
      padding: 10px;
      margin: 0px 117px;
    }

    h1 {
      padding: 10px 40px;
      text-transform: uppercase;
      font-size: 55px !important;
      letter-spacing: 0.02em;
    }
  }


  .img_div {
    align-items: end;
  }

  .txt_div,
  .img_div {
    gap: 18px;
    display: flex;
    flex-wrap: wrap;
    color: #252525;
  }

  .style_div {
    .line {
      width: 126px;
      background: #252525;
      height: 6px;
      border-radius: 10px;
      display: flex;
    }
  }

  i {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
  }

  img.second {
    margin-top: 40px;
  }

  .first_content {
    margin-bottom: -40px;
  }

    
  .mob_div{
    padding:0 40px;
    display:none;



    .slick-slide img {
    width: 100%;
    height: 332px;
    object-fit: contain;
}

.slick-track {
  display:flex;
  gap:20px;
}


  }



  @media (max-width: 567px) {
    display:unset;
    .txt_div,
    .img_div {
      justify-content: center;
      gap: 40px;
    }

     img.second {
      margin-top: 0px;
    }
    .style_div {
    display: flex;
    flex-direction: column;
    align-items: center;
}
 i {
    align-items: center;
}

.first_content {
    margin-bottom: -40px;
    text-align: center;
}
.mob_div .slick-slide img {
    width: 100%;
height: 100%; 
    object-fit: contain;
}
   /*
/*
    h1 {
      font-size: 42px !important;
      line-height: 55px !important;
    }



    .first_content {
      margin-bottom: 0px;
    } */
      .text_div .main_heading {
      margin: 0px;
    }
   .text_div {
    margin: 60px  0px 0px;
    padding: 0px;
}

margin:0;
    .desk_div{
        display:none;
      }

  .mob_div{
    padding:0 20px;
    display:block;
    margin:80px 0 30px;
  }

  .head_cont_div{
      display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

  }

  @media (min-width: 567px) and (max-width: 992px) {

    .first_content {
      margin-bottom: 0px;
    }

    .txt_div,
    .img_div {
      justify-content: space-evenly;
    }

    .text_div .main_heading {
      margin: 0px 30px;
    }

    .text_div {
      margin: 10px 0;
      padding: unset;
    }

    h1 {
      font-size: 55px !important;
      line-height: 71px !important;
    }

    .mob_div{
    display:none;
  }

  
  }
`;
