import React from "react";
import styled from "styled-components";
import { PriButton, PriHeader, PriText, TextHead } from "../Global";
import leave from "../Images/leave.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function Sec7() {
  return (
    <Root>
      <div className="main_div">
        <PriHeader style={{ color: "white", textAlign: "left" }}>
          How we ended up being a part of 12 million Dollar project ?
        </PriHeader>

        <div className="desk_div">
          <TextHead style={{ color: "white", textAlign: "left" }}>
            Our Involvement in Bollywood's 2023 Blockbuster "Animal"
          </TextHead>

          <PriText style={{ color: "white", textAlign: "left" }}>
            We are grateful and humbled to announce our role in Bollywood's 2023
            blockbuster project, "Animal," which was shot in the outskirts of
            vibrant city of London. This project was an extraordinary experience
            for us, pushing the boundaries of our capabilities and immersing us
            in the latest cutting-edge technology used in film production. Our
            team contributed to the Director of Photography (DOP) segment and
            the electronic equipment department, ensuring that every shot was
            captured with precision and innovation.
          </PriText>

          <PriText style={{ color: "white", textAlign: "left" }}>
            Being a part of "Animal" was an eye-opening journey that not only
            enhanced our technical expertise but also solidified our confidence
            to engage in similar high-profile projects in the future. The
            collaboration allowed us to work alongside some of the industry's
            best talents like Ranbir Kapoor, Bobby Deol, Sandeep Reddy Venga and
            allowed us to apply our talent to state-of-the-art equipment and
            techniques in real-world settings.
          </PriText>

          <PriText style={{ color: "white", textAlign: "left" }}>
            We are truly humbled and grateful for the opportunity to contribute
            to such a monumental film. The lessons learned and the skills
            acquired during this project have been invaluable, and we look
            forward to bringing this experience to our future endeavours.
          </PriText>
        </div>

        <div className="mob_acc bgmac" data-aos="fade-left">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                <TextHead style={{ color: "white", textAlign: "left" }}>
                  Our Involvement in Bollywood's 2023 Blockbuster "Animal" We are grateful and
                </TextHead>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <PriText style={{ color: "white", textAlign: "left" }}>
                  humbled to announce our role in
                  Bollywood's 2023 blockbuster project, "Animal," which was shot
                  in the outskirts of vibrant city of London. This project was
                  an extraordinary experience for us, pushing the boundaries of
                  our capabilities and immersing us in the latest cutting-edge
                  technology used in film production. Our team contributed to
                  the Director of Photography (DOP) segment and the electronic
                  equipment department, ensuring that every shot was captured
                  with precision and innovation.
                </PriText>
                <br />
                <PriText style={{ color: "white", textAlign: "left" }}>
                  Being a part of "Animal" was an eye-opening journey that not
                  only enhanced our technical expertise but also solidified our
                  confidence to engage in similar high-profile projects in the
                  future. The collaboration allowed us to work alongside some of
                  the industry's best talents like Ranbir Kapoor, Bobby Deol,
                  Sandeep Reddy Venga and allowed us to apply our talent to
                  state-of-the-art equipment and techniques in real-world
                  settings.
                </PriText>
                <br />

                <PriText style={{ color: "white", textAlign: "left" }}>
                  We are truly humbled and grateful for the opportunity to
                  contribute to such a monumental film. The lessons learned and
                  the skills acquired during this project have been invaluable,
                  and we look forward to bringing this experience to our future
                  endeavours.
                </PriText>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>

        <div className="connect_btn ">
          <PriButton style={{ width: "200px", margin: "20px 0px" }}>
            Request for Quotation
          </PriButton>
        </div>
      </div>
    </Root>
  );
}

export default Sec7;

const Root = styled.section`
  .main_div {
    border: 1px solid black;
    width: 100%;
    background-size: 100% 100%;
    background-image: url(${leave});
    padding: 40px 50px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .fVMfg {
    text-align: left !important;
    color: white !important;
  }
  .fzMLWp {
    color: white !important;
  }

  .fbRzwc {
    color: white;
  }

  .mob_acc {

    display: none;
  }

  .desk_div {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .connect_btn {
    display: flex;
    justify-content: flex-start;
    .gjLOFG {
      background: unset !important;
      border: 1px solid white;
      padding: 16px 12px !important;
    }
  }

  @media (max-width: 567px) {
    .main_div {
      gap: 10px;
      padding: 40px 20px;
    }
    .kVlhYp{
      font-size:25px!important;
      line-height:35px;
    }

    .jQWICa {
      font-size: 14px;
      line-height: 30px;
    }

    .jwQJAj {
      font-size: 14px;
      line-height: 30px;
    }
    .mob_acc {
      padding: 0;
    display: block;
    margin-top:20px;
  }

    .desk_div {
      display: none;
    }

    .css-1086bdv-MuiPaper-root-MuiAccordion-root {
    background-color:transparent!important;
    border: 2px solid white!important;
}

 .bgmac .css-i4bv87-MuiSvgIcon-root {
    color:white!important;
}

  }

  @media (min-width: 567px) and (max-width: 992px) {
    .mob_acc {
    display: none;
  }
  }
`;
