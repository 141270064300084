import React, { useEffect } from "react";
import styled from "styled-components";
import lavander from "../Images/Lavender.png";
import a1 from "../Images/a1.png";
import { PriButton, PriHeader, SpanText } from "../Global";
import a2 from "../Images/a2.png";
import a3 from "../Images/a3.png";
import a4 from "../Images/a4.png";
import a5 from "../Images/a5.png";
import a6 from "../Images/a6.png";
import Slider from "react-slick";
import Aos from "aos";

// import { UseNavigate, useNavigate } from "react-router-dom";

function Sec6() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  var settings = {
    dots:true,
    infinite:true,
    slidesToShow:3,
    slidesToScroll:3,
    initialSlide:0,
    autoplay:true,
    speed:2000,
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Root>
      
      <div className="heading">
        <img src={lavander} alt="img"/>
        <PriHeader ata-aos="fade-up">Celebrities we had the chance to work with</PriHeader>
      </div>
      
      <div className="desk_div">
      <div className="content_div" >
        <div className="first">
          <a href="portfolio" >
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Ranbir Kapoor</SpanText>
            <img src={a1} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>

          <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Farhana Modi</SpanText>
            <img src={a4} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>
        </div>

        <div className="sec">
        <a href="portfolio">
          <div className="sec_one" data-aos="zoom-in">
            <SpanText>Bobby Deol</SpanText>
            <img src={a2} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
        </a>

        <a href="portfolio">
          <div className="sec_one" data-aos="zoom-in">
            <SpanText>Kanika Kapoor</SpanText>
            <img src={a5} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>
        </div>

        <div className="first">
        <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Dwayne Bravo</SpanText>
            <img src={a3} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>

          <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Christopher Nolan</SpanText>
            <img src={a6} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>
        </div>
      </div>
      </div>

      <div className="mob_div">
      <div className="slider-container">
      <Slider {...settings}>
        <div>

        <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Ranbir Kapoor</SpanText>
            <img src={a1} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
        </a>

        </div>
        <div>
        <a href="portfolio">
          <div className="sec_one" data-aos="zoom-in">
            <SpanText>Bobby Deol</SpanText>
            <img src={a2} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
        </a>
        </div>
        <div>
        <a href="portfolio">
          <div className="first_one"data-aos="zoom-in">
            <SpanText>Dwayne Bravo</SpanText>
            <img src={a3} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
        </a>
        </div>
        <div>
        <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Farhana Modi</SpanText>
            <img src={a4} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>
        </div>
        <div>
        <a href="portfolio">
          <div className="sec_one" data-aos="zoom-in">
            <SpanText>Kanika Kapoor</SpanText>
            <img src={a5} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
        </a>
        </div>
        <div>
        <a href="portfolio">
          <div className="first_one" data-aos="zoom-in">
            <SpanText>Christopher Nolan</SpanText>
            <img src={a6} alt="img" />
            <div
              style={{
                padding: "10px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
              </SpanText>
            </div>
          </div>
          </a>
        </div>

      </Slider>
    </div>



      </div>

      <div className="connect_btn ">
        <PriButton style={{ width: "150px", margin: "20px 0px" }}>
          Call Us
        </PriButton>
      </div>

    </Root>
  );
}

export default Sec6;
const Root = styled.section`
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;

  a{
    text-decoration: none;
  }

  .heading {
    text-align: center;
    img {
      margin: 10px 0px;
    }
  }
  .content_div {
    display: flex;
    padding: 0px 40px;
    gap: 25px;
    margin: 35px 0px;

    .first,
    .sec {
      display: flex;
      flex-direction: column;
      gap: 25px;
      flex: 1;
    }
    .first_one,
    .sec_one {
      display: flex;
      flex-direction: column;
    }
  }
  .first-one{
    font-weight: 500;
  }

  .mob_div{
    padding:0 40px;
    display:none;

    .slick-slide img {
    width: 100%;
}

.slick-track {
  display:flex;
  gap:20px;
}
  }
  
  .connect_btn {
    display:flex;
    justify-content:center;
  }
 
  @media (max-width: 567px) {
    /* .kucZgf {
      line-height: unset;
    }
    .content_div {
      padding: 0;
      flex-wrap: wrap;
    }

    .content_div .first,
    .DhiBD .content_div .sec {
      flex: unset;
    }

    .content_div .first_one img,
    .content_div .sec_one img {
      width: 100%;
    } */

      .desk_div{
        display:none;
      }

      .mob_div{
    padding:0 20px;
    display:block;
    margin:30px 0;
  }

  }

  @media (min-width: 567px) and (max-width: 992px) {
    .content_div {
      padding: 0px;
    }
    .content_div .first_one img,
    .content_div .sec_one img {
      width: 100%;
    }
    .mob_div{
    display:none;
  }
  }
`;
