import React from "react";
import Sec1 from "./Sec1";
import Sec2 from "./Sec2";
import Sec3 from "./Sec3";
import Sec4Camera from "./Sec4Camera";
import Sec5 from "./Sec5";
import Sec6 from "./Sec6";
import Testimonial from "./Testimonial";
import Sec7 from "./Sec7";


function Home(){

  return(
    <div>
      <Sec1/>
      <Sec3/>
      <Sec5/>
      <Sec7/>
      <Sec2/>    
      <Sec6/>
      <Testimonial/>
      <Sec4Camera/>
    </div>
  );
}

export default Home;
