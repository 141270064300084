import React, { useEffect } from "react";
import styled from "styled-components";
import camera from "../Images/pexels-alex-andrews-1203803 1.png";
import { Input, LargeHeader, PriButton, PriText } from "../Global";
import bord from "../Images/bord.png";
import Aos from "aos";


function Sec4Camera() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="main_div"   data-aos="zoom-in-down"
     >
        <div className="camera_img">
          <img src={camera} alt="img" />
        </div>
        <div className="form">
          <LargeHeader>let's connect</LargeHeader>
          <div className="form_field">
            <div className="input">
              <PriText>first name</PriText>
              <Input type="text" placeholder="first name" />
            </div>
            <div className="input">
              <PriText>last name</PriText>
              <Input type="text" placeholder="last name" />
            </div>
          </div>
          <div>
            {/* <div>
             
            </div> */}

            <div className="form_field">
              <div className="input">
                <PriText>email address</PriText>
                <Input  tyep="email"placeholder="email address" className="mail_section" />
              </div>
              <div className="input">
                <PriText>Timing</PriText>
                <Input type="date" placeholder="Date"  className="timing_div"/>
              </div>
            </div>
          </div>
          <div className="but_main">
          <PriButton  className="bttn_div">
            Lets Start Shooting
          </PriButton>
          </div>
        </div>
      </div>
    </Root>
  );
}

export default Sec4Camera;
const Root = styled.section`
  display: flex;
  margin: 40px;
  justify-content: center;
  .main_div {
    margin: 40px;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    background-image: url(${bord});
    background-size: 100% 100%;
  }
  .camera_img {
    flex: 1;
  }
  .form {
    padding: 40px;
    .input {
      display: flex;
      flex: 1;
      flex-direction: column;
      flex-wrap: wrap;
    }
    p {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      text-transform: capitalize;
    }

    .form_field {
      display: flex;
      gap: 20px;
      margin: 20px 0px;
    }

    /* .mail_section {
      width: 461px;
    } */
  }
  .bttn_div{
    width:200px;
    margin:40px 0;
  }

  @media (max-width: 567px) {
    display: unset;
    margin: 20px;
    justify-content: center;

    .main_div {
      margin: 20px;
      gap: 40px;
      .camera_img {
        img {
          width: 100%;
        }
      }
    }

    .bdjKJh {
      font-size: 50px;
      line-height: unset;
    }

    .camera_img {
      flex: unset;
      margin-top: 40px;
    }

    .form .form_field {
      display: flex;
      flex-direction: column;
    }
    .form {
      padding: unset;
      width: 100%;
    }

    .form .mail_section {
      width: 100%;
    }
    .timing_div{
      width:100%;
    }
   .bttn_div {
    width: 100%;
    margin: 20px 0;
}
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .main_div {
      margin: 0;
      padding-top: 65px;
    }
    .form {
      padding-bottom: 0;
    }
    .camera_img {
      flex: unset;
    }
    .but_main {
    display: flex;
    justify-content: center;
}
  }
`;
