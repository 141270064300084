import React from "react";
import styled from "styled-components";
import Sec4Camera from "../home/Sec4Camera";

export default function Sec4() {
  return (
    <Root>
     <Sec4Camera/>
    </Root>
  );
}

const Root = styled.section`

 
`;
