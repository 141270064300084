import React, { useEffect } from "react";
import styled from "styled-components";

import fash6 from "../Images/fash6.png";
import { Subhead, TextHead} from "../Global";
import Aos from "aos";

export default function Sec3() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="gourmet_content">
      <div className="content_div"  data-aos="fade-left">
          <Subhead>Lookbook Photography</Subhead>
          <TextHead>Elevate your brand with professional lookbook photography that highlights your latest collections and seasonal trends. Our team will collaborate with you to create visually striking images that resonate with your target audience and drive sales.          </TextHead>
        </div>
        <img src={fash6} alt="fash6"   data-aos="fade-right"/>

      </div>



    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;
  border-top: 0.5px solid #292A36;

  
  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }
  @media (max-width: 567px) {
    padding: 40px 20px;
    .gourmet_content {
    flex-wrap: wrap;
    margin-top: 0px;
    gap: 30px;
    img {
    width: 100%;
}
}
.iqFyfv {
    font-size: 31px;
    line-height: unset;
}

.beimEb {
    line-height: unset;
}
  }
  
  @media (min-width: 567px) and (max-width: 992px){
  
  padding: 40px 20px;
  .kucZgf {
    font-size: 45px;
    line-height: unset;
  }
  .gourmet_content {
    flex-wrap: wrap;
    gap: 30px;
    margin-top:0;
    img {
      width: 100%;
      height: 100%;
    }

    .iqFyfv {
      font-size: 32px;
      line-height: unset;
    }
  }

}

  
`;
