import React, { useEffect } from "react";
import styled from "styled-components";
import { PriHeader, PriText, SecButton, SpanText } from "../Global";
import floral from "../Images/Floral design.png";
import pict from "../Images/Picture.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";


function Sec2() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <Root>
      <div className="heading" data-aos="fade-up">
        <img src={floral} alt="img" />
        <PriHeader>Work with our Award winning Director</PriHeader>
      </div>
      <div className="body_div">
        <div className="text_div_main">
          <div className="desc_content" data-aos="fade-right">
            <PriText>
              When we say we are good it means proof is in the Pudding.
            </PriText>
            <PriText>
              We are humbled and Thrilled to announce that{" "}
              <SpanText> Aamir Nawab from TimelessFilms.Media</SpanText> has{" "}
              <SpanText>won the Best Director award</SpanText> for his short
              film "The Writer" at the prestigious London Film Festival.
            </PriText>
            <PriText>
              This award recognizes Nawab's exceptional talent and vision in
              filmmaking. "The Writer," celebrated for its powerful narrative
              and innovative direction, stands as a testament to Nawab's
              dedication to the craft.
            </PriText>
          </div>
          <div className="mob_acc"  data-aos="fade-right">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  {" "}
                  <PriText>
                    When we say we are good it means proof is in the Pudding.
                    <br />
                    We are humbled and Thrilled to announce that{" "}
                  </PriText>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <PriText>
                    <SpanText> Aamir Nawab from TimelessFilms.Media</SpanText>{" "}
                    has <SpanText>won the Best Director award</SpanText> for his
                    short film "The Writer" at the prestigious London Film
                    Festival.
                  </PriText>
                  <br />
                  <PriText>
                    This award recognizes Nawab's exceptional talent and vision
                    in filmmaking. "The Writer," celebrated for its powerful
                    narrative and innovative direction, stands as a testament to
                    Nawab's dedication to the craft.
                  </PriText>
                  <br />
                </Typography>

                <SecButton>KNOW MORE </SecButton>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>

        <div className="side_img" data-aos="fade-left">
          <img src={pict} alt="img" />
        </div>
      </div>
    </Root>
  );
}

export default Sec2;
const Root = styled.section`
  /* margin: 40px 0px; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  .heading {
    text-align: center;
    img {
      margin: 10px 0px;
    }
  }
  .body_div {
    display: flex;
    margin: 40px;
  }
  .text_div_main,
  .side_img {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .text_div_main {
    padding: 50px;
    justify-content: end;
    gap: 30px;
  }

  .mob_acc {
    display: none;
  }

  @media (max-width: 567px) {
    .kucZgf {
      line-height: unset;
    }
    .body_div {
      margin: unset;
      flex-wrap: wrap;
    }

    .text_div_main {
      padding: 10px;
    }

    .side_img {
      flex: unset;
      img {
        width: 100%;
      }
    }

    .desc_content {
      display: none;
    }
    .mob_acc {
      display: block;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .body_div {
      margin: unset;
      flex-wrap: wrap;
    }

    .text_div_main {
      padding: 20px;
    }
    .mob_acc {
      display: none;
    }
  }
`;
