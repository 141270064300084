import React, { useEffect } from "react";
import styled from "styled-components";
import fash1 from "../Images/fash1.png";
import fash2 from "../Images/fash2.png";
import fash3 from "../Images/fash3.png";
import fash4 from "../Images/fash4.png";
import { PriHeader, TextHead } from "../Global";
import Slider from "react-slick";
import Aos from "aos";


export default function Sec1() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  var settings = {
    dots:true,
    infinite:true,
    slidesToShow:3,
    slidesToScroll:3,
    initialSlide:0,
    autoplay:true,
    speed:2000,
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <Root>
      <div className="main_cont" data-aos="fade-up">
        <PriHeader>Fashion Photography</PriHeader>
        <TextHead style={{textAlign:"center"}}>
          We take Fashion as seriously as you do and that is why we specialize
          in capturing the essence of style and sophistication through our
          fashion photography and videography services.
        </TextHead>
        <TextHead style={{textAlign:"center"}}>
          With our keen eye for detail and commitment to creativity, we bring
          fashion to life in captivating imagery and dynamic videos.
        </TextHead>
      </div>

      <div className="desk_div">
      <div className="main_images">
        <img src={fash1} alt="food1" className="fashone" data-aos="zoom-in"/>

        <div className="inline_images">
          <img src={fash2} alt="fash2" />
          <img src={fash3} alt="fash3" />
          <img src={fash4} alt="fash4" />
        </div>
      </div>
      </div>

      <div className="mob_div" data-aos="fade-up">
      <div className="slider-container">

      <Slider {...settings}>
        <div>
          <img src={fash1} alt="food1"/>
        </div>


        <div>
              <img src={fash2} alt="fash2" />
        </div>

        <div>
        <img src={fash3} alt="fash3" />

        </div>

        <div>

        <img src={fash4} alt="fash4" />

        </div>

 



      </Slider>
    </div>



      </div>

    </Root>
  );
}

const Root = styled.section`
  padding: 120px 54px 40px;
  .main_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;

    h1{
      font-size: 55px !important ;
      letter-spacing: 0.02em;
    }

  }
  .main_images {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;

    .inline_images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      img {
        width: 400px;
        height: 344px;
      }
    }
  }

 
  .mob_div{
    display:none;

    .slick-slide img {
    width: 100%;
}

.slick-track {
  display:flex;
  gap:20px;
}
  }

  @media (max-width: 567px) {
    /* padding: 40px 20px;

    .main_images .inline_images img {
    width: 100%;
} */
    padding: 40px 20px;
    .desk_div {
      display: none;
    }

    .mob_div {
      display: block;
      margin: 30px 0;
      .slick-slide img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-radius: 20px;
      }
    }


  

  }

  @media (min-width: 567px) and (max-width: 992px){
    padding: 60px 20px;
    .main_images .inline_images img {
    width: 48%;
}

.mob_div{
    display:none;
  }


  }

`;
