import React from "react";
import styled from "styled-components";
import {
  Contactdesc,
  Contacthead,
  Contactinfo,
  Contactlink,
  Contactsubhead,
  Input,
  PriButton,
} from "../Global";
import rect from "../Images/rect.jpg";
import mail from "../Images/mail.png";
import location from "../Images/location.png";
import instag from "../Images/instag.png";
import tik from "../Images/tik.png";
import linked from "../Images/linked.png";

export default function Contact() {
  return (
    <Root>
      <div className="contact_div">
        <Contacthead>Contact Us</Contacthead>
        <Contactsubhead>
          Any question or remarks? Just write us a message!
        </Contactsubhead>
      </div>
      <div className="contact_form">
        <div className="cont_info">
          <div className="content_heading">
            <Contactinfo>Contact Information</Contactinfo>
            <Contactdesc>Say something to start a live chat!</Contactdesc>
          </div>

          <div className="cont_link">
            {/* <a href="/">
              {" "}
              <img src={phone} /> <Contactlink>+1012 3456 789</Contactlink>
            </a> */}
            <a href="/">
              {" "}
              <img src={mail}  alt="img"/> <Contactlink>demo@gmail.com</Contactlink>
            </a>
            <a href="/">
              {" "}
              <img src={location}  alt="img"/>{" "}
              <Contactlink>
                132 Dartmouth Street Boston, Massachusetts 02156 United States
              </Contactlink>
            </a>
          </div>

          <div className="social_link">
            <a href="/">
              {" "}
              <img src={instag}  alt="instag"/>{" "}
            </a>
            <a href="/">
              {" "}
              <img src={tik}   alt="tiktok"/>{" "}
            </a>
            <a href="/">
              {" "}
              <img src={linked}  alt="linked" />{" "}
            </a>
          </div>
        </div>
        <div className="form_info">
          <div className="fields">
            <div className="input">
              <p>first name</p>
              <Input type="text" placeholder="First name" />
            </div>
            <div className="input">
              <p>last name</p>
              <Input type="text" placeholder="last name" />
            </div>
          </div>

          <div className="fields">
            <div className="input">
              <p>Email</p>
              <Input type="email" placeholder="abc@gmail.com" />
            </div>
            <div className="input">
              <p>Phone Number</p>
              <Input type="tel" placeholder="+1 012 3456 789" />
            </div>
          </div>

          <div className="fields">
            <div className="input">
              <p>Select Subject?</p>
              <div className="check_div">
                <label class="main">
                  General Inquiry
                  <input type="checkbox" />
                  <span class="checkbox-container"></span>
                </label>

                <label class="main">
                  Package Inquiry
                  <input type="checkbox" />
                  <span class="checkbox-container"></span>
                </label>

                <label class="main">
                  General Inquiry
                  <input type="checkbox" />
                  <span class="checkbox-container"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="fields">
            <div className="input">
              <p>Message</p>
              <textarea rows="4" cols="50" placeholder="Write your message..">
                {" "}
              </textarea>
            </div>
          </div>

          <div className="form_btn">
            <PriButton style={{ width: "200px" }}>Send Message</PriButton>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 80px 100px;

  .contact_div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact_form {
    display: flex;
    margin-top: 50px;
    border-radius: 10px;

    .cont_info {
      width: 40%;
      background-image: url(${rect});
      background-repeat: no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      padding: 50px 30px;
      justify-content: space-between;

      .content_heading {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .cont_link {
        display: flex;
        flex-direction: column;
        gap: 40px;

        a {
          display: flex;
          gap: 30px;
          text-decoration: none;
          img,
          svg {
            vertical-align: middle;
            width: 20px;
            height: 20px;
          }
        }
      }

      .social_link {
        display: flex;
        gap: 20px;
        img{
          width:35px;
          height:35px;
          border-radius: 30px;
          border:1px solid transparent;
        }
      }
    }

    .form_info {
      width: 60%;
      box-shadow: 0px 0px 60px 30px #00000008;
      padding: 50px 30px;
      gap: 40px;
      display: flex;
      flex-direction: column;

      .fields {
        display: flex;
        gap: 40px;
        .input {
          display: flex;
          flex: 1;
          flex-direction: column;
          flex-wrap: wrap;
        }
        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 400;
          line-height: 10px;
          text-align: left;
          text-transform: capitalize;
        }
        textarea {
          padding-left: 10px;
          border: 0.5px solid #4e4e4e;
          height: 127px;
          border-radius: 3px;
          padding-left: 10px;
          width: 100%;
        }
        .check_div {
          display: flex;
          gap: 25px;
          .main {
            display: block;
            position: relative;
            padding-left: 25px;
            margin-bottom: 15px;
            cursor: pointer;
            font-family: Poppins;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            text-align: left;
          }

          .main input[type="checkbox"] {
            visibility: hidden;
          }

          .checkbox-container {
            position: absolute;
            top: 3px;
            left: 0;
            height: 14px;
            width: 13px;
            border-radius: 30px;
            background-color: #e0e0e0;
          }

          /* .main:hover input ~ .checkbox-container {
            background-color: yellow;
          } */

          .main input:active ~ .checkbox-container {
            background-color: #000000;
          }

          .main input:checked ~ .checkbox-container {
            background-color: #000000;
          }

          .checkbox-container::after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: -2px;
            width: 6px;
            height: 12px;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }

          .main input:checked ~ .checkbox-container::after {
            display: block;
          }
        }
      }

      .form_btn {
        display: flex;
        justify-content: end;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 20px 20px;
    .contact_form {
      flex-wrap: wrap;
    }
    .contact_form .cont_info {
      width: 100%;
      gap: 50px;
    }

    .contact_form .form_info {
      width: 100%;
    }
    .contact_form .form_info .fields {
      flex: 1;
      flex-direction: column;
    }

    .contact_form .form_info .fields .check_div {
      flex-wrap: wrap;
      gap: 10px;
    }

    .contact_form .form_info .form_btn {
      justify-content: center;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 20px 20px;

    .contact_form {
      flex-wrap: wrap;
    }
    
    .contact_form .cont_info {
      width: 100%;
      gap: 50px;
    }

    .contact_form .form_info {
      width: 100%;
    }


  }
`;
