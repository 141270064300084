import React from "react";
import styled from "styled-components";
import port from "../Images/port.png";
import cof from "../Images/wedding8.png";
import logo from "../Images/fash5.png";
import { PriButton } from "../Global";

function Portfolio() {
  return (
    <Root>
      <div className="bg_img"></div>

      <div className="main_div">
        <div className="sub_div_one">
          <div className="logo">
            <img src={logo} alt="logo img" />
          </div>
          <div className="profile">
            <h2>Lorem Ipsum</h2>
            <p> Lorem ipsum dolor sit</p>
          </div>

          <div className="about_div">
            <h2>ABOUT ME</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. imperdiet
              sed id elementum. Quam vel aliquam sit vulputate. Lorem ipsum
              dolor sit amet, consectetur adipiscing elit. imperdiet sed id
              elementum. Quam vel aliquam sit vulputate. Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. imperdiet sed id elementum.
              Quam vel aliquam sit vulputate.
            </p>
          </div>
        </div>
        <div className="sub_div_two">
          <PriButton>
            work{" "}
          </PriButton>

          <div className="fortfolio_img">
            <div className="containerrr">
              <img
                src={cof}
                alt="Avatar"
                class="className"
                style={{ width: "100%" }}
              />
              <div className="middle">
                <div className="text">Brand Logo</div>
              </div>
            </div>
            <div className="containerrr">
              <img
                src={cof}
                alt="Avatar"
                class="className"
                style={{ width: "100%" }}
              />
              <div className="middle">
                <div className="text">Brand Logo</div>
              </div>
            </div>
            <div className="containerrr">
              <img
                src={cof}
                alt="Avatar"
                class="className"
                style={{ width: "100%" }}
              />
              <div className="middle">
                <div className="text">Brand Logo</div>
              </div>
            </div>
            <div className="containerrr">
              <img
                src={cof}
                alt="Avatar"
                class="className"
                style={{ width: "100%" }}
              />
              <div className="middle">
                <div className="text">Brand Logo</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Root>
  );
}

export default Portfolio;

const Root = styled.section`
  padding: 0 0 40px;
  .bg_img {
    height: 300px;
    width: 100%;
    background-image: url(${port});
    background-size: 100% 100%;
  }

  .main_div {
    display: flex;
    padding: 0 50px;
    gap: 50px;
    .sub_div_one {
      width: 30%;
      padding: 25px 30px 16px;
      box-shadow: 0 1px 3px rgba(25, 25, 25, 0.1);
      background-color: #fff;
      border-radius: 3px;
      margin-top: -90px;
      .logo {
        display: flex;
        justify-content: center;
        img {
          width: 110px;
          height: 110px;
          object-fit: cover;
          border-radius: 60px;
        }
      }
      .profile {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        h2 {
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 0;
          font-family: "Bayon";
        }

        p {
          font-size: 15px;
          font-family: Poppins;
          line-height: 1.4;
          margin: 1px 0 0 0;
          color: #696969;
          font-weight: 500;
        }
      }

      .location {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0;
        color: #696969;
        gap: 20px;
        p {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 0;
        }
      }

      .about_div {
        margin-top: 30px;
        h2 {
          color: #707070;
          font-size: 11px;
          font-weight: bold;
          text-transform: uppercase;
        }
        p {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .sub_div_two {
      width: 70%;
      padding-top: 30px;
      .gjLOFG {
    width: 121px;
   
}

      .fortfolio_img {
        margin-top: 30px;
        display: flex;
        gap: 15px;
        flex-wrap: wrap;

        .containerrr {
          position: relative;
          width: 49%;
          img {
            border-radius: 5px;
          }
        }

        .image {
          opacity: 1;
          display: block;
          width: 100%;
          height: auto;
          transition: 0.5s ease;
          backface-visibility: hidden;
        }

        .middle {
          opacity: 0;
          position: absolute;
          top: 85%;
          left: 3%;
          /* / transform: translate(-50%, -50%); /
        / -ms-transform: translate(-50%, -50%); / */
          text-align: center;
        }

        .containerrr:hover .image {
          opacity: 0.3;
        }

        .containerrr:hover .middle {
          opacity: 1;
        }

        .text {
          color: white;
          font-size: 16px;
          font-weight: 700;
        }
      }
    }
  }
  @media (max-width: 567px) {
    overflow: hidden;
    .bg_img {
      height: 170px;
    }

    .main_div {
      display: flex;
      flex-wrap: wrap;
      padding: 0 22px;
      gap: 0;
    }

    .main_div .sub_div_one {
      width: 100%;
      margin-top: -70px;
    }

    .main_div .sub_div_two {
      width: 100%;
    }

    .main_div .sub_div_two .fortfolio_img .containerrr {
      width: 47%;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .main_div {
      display: flex;
      flex-wrap: wrap;
      padding: 0 22px;
      gap: 0;
    }

    .main_div .sub_div_one {
      width: 100%;
      margin-top: -100px;
    }

    .main_div .sub_div_two {
      width: 100%;
    }

    .main_div .sub_div_two .fortfolio_img .containerrr {
      width: 47%;
    }
  }
`;
