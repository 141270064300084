import React, { useEffect } from "react";
import styled from "styled-components";
import d from "../Images/d.png";
import a from "../Images/a.png";
import Aos from "aos";

export default function Sec1() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="main_div">
        <div className="welcome_content" data-aos="fade-right">
          <img src={d} alt="d_img" />

          <h1 >
            Welcome to
            <br /> TimelessFilms.Media
          </h1>
          <h2>
            Where passion meets expertise in the realm of photography and
            videography. With a combined experience of 10 years, our team brings
            a wealth of creativity and technical skill to every project.
          </h2>
        </div>
        <div className="welcome_img" data-aos="fade-left">
          <img src={a} alt="a_img" />
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 120px 54px 40px;
  .main_div {
    display: flex;
    flex-wrap: wrap;

    .welcome_content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;

      img {
        width: 176px;
        height: 88px;
      }
    }

    .welcome_img {
      flex: 1;
      display: flex;
      justify-content: end;
      img {
        width: 541px;
        height: 672px;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;

    .main_div .welcome_content {
      flex: unset;
      width: 100%;
 
    }

    .main_div .welcome_img {
      flex: unset;
      margin-top:40px;

      img {
        width: 100%;
        height:100%;
      }
    }
  }

  @media (min-width: 567px) and (max-width: 992px){

    .main_div .welcome_content {
      flex: unset;
      width: 100%;

    }

    .main_div .welcome_img {
      flex: unset;
      margin-top:40px;
      width: 100%;

      img {
        width: 100%;
        height:100%;
      }
    }

  }
`;
