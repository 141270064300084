import React, { useRef } from "react";
import Slider from "react-slick";
import test from "../Images/test.jpg";
import styled from "styled-components";
import { PriHeader, SpanText } from "../Global";

function Testimonial() {
  let sliderRef = useRef(null);
  const settings = {
    dots:false,
    infinite:true,
    slidesToShow:2,
    slidesToScroll:1,
    autoplay:true,
    autoplaySpeed:2000,
    responsive:[
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>
    <PriHeader>TESTIMONIALS CLIENT</PriHeader>

      <div className="slider-container">
        <Slider ref={(slider) => (sliderRef = slider)} {...settings}>
            

          <div>
            <div className="test-content">
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </SpanText>
              <div className="profile_desc">
                <img src={test} alt="img" />
                <div className="profile_content">
                  <h4>Mitcheal Starc</h4>
                  <p>CEO</p>
                </div>
              </div>
            </div>
          </div>


          <div>
            <div className="test-content">
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </SpanText>
              <div className="profile_desc">
                <img src={test} alt="img" />
                <div className="profile_content">
                  <h4>Roy Tailor</h4>
                  <p>Photographer</p>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="test-content">
              <SpanText style={{ fontWeight: "400" }}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                imperdiet sed id elementum. Quam vel aliquam sit vulputate.
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </SpanText>
              <div className="profile_desc">
                <img src={test} alt="img" />
                <div className="profile_content">
                  <h4>Jacky</h4>
                  <p>Fashion Designer</p>
                </div>
              </div>
            </div>
          </div>


        </Slider>
      </div>
    </Root>
  );
}

export default Testimonial;

const Root = styled.section`
  padding: 20px 40px ;

  .slick-list {
    padding: 35px 13px;
  }


  .test-content {
    border:1px solid #dadada;
    border-radius:30px;
    display:flex;
    flex-direction:column;
    gap:30px;
    padding:35px;
    margin-right:20px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    h2 {
      color: #6e6e6e;
      font-size: 20px;
      font-weight: 700;
      font-style: italic;
      line-height: 27px;
    }
    .profile_desc {
      display: flex;
      gap: 20px;
      img {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        object-fit: cover;
      }
      .profile_content {
        display: flex;
        flex-direction: column;
        h4 {
          margin-bottom: 0;
          font-size: 20px;
          font-weight: 700;
        }
        p {
          font-size: 14px;
          font-style: normal;
        }
      }
    }
  }

  .slick-track {
    display: flex;
    gap: 20px;  
  }

  @media (max-width: 567px) {
    padding:20px 0;


  }
`;
