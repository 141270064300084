import React, { useEffect } from "react";
import styled from "styled-components";

import fash10 from "../Images/fash10.png";
import { Subhead, TextHead } from "../Global";
import Aos from "aos";

export default function Sec4() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="gourmet_content">
        <img src={fash10} alt="fash10"  data-aos="fade-right" />
        <div className="content_div"  data-aos="fade-left">
          <Subhead>Model Portfolios</Subhead>
          <TextHead>
            Help your models stand out in a competitive industry with
            professional portfolio photoshoots. Our experienced photographers
            will highlight their unique features and talents, providing them
            with the perfect portfolio to impress agencies and clients alike.
          </TextHead>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;
  border-top: 0.5px solid #292a36;

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;
    .gourmet_content {
      flex-wrap: wrap;
      margin-top: 0px;
      gap: 30px;
      img {
        width: 100%;
      }
    }
    .iqFyfv {
      font-size: 32px;
      line-height: unset;
    }

    .beimEb {
      line-height: unset;
    }
  }
  @media (min-width: 567px) and (max-width: 992px) {
    padding: 40px 20px;
    .kucZgf {
      font-size: 45px;
      line-height: unset;
    }
    .gourmet_content {
      flex-wrap: wrap;
      gap: 30px;
      img {
        width: 100%;
        height: 100%;
      }

      .iqFyfv {
        font-size: 32px;
        line-height: unset;
      }
    }
  }
`;
