import React from "react";
import styled from "styled-components";
import c from "../Images/c.png";

export default function Sec3() {
  return (
    <Root>
      <div className="main_div">
        <div className="content_div" data-aos="fade-right">
          <h2>
            Our dedication to cinematic excellence was recognized on an
            international stage when our director was awarded the prestigious
            Best Director title at the London Film Festival for our short film
            'The Writer.'
          </h2>
          <h2>
            At TimelessFilms.Media, we are not just storytellers; we are
            creators of timeless moments, weaving narratives that resonate
            deeply with audiences worldwide.{" "}
          </h2>
          <h2>
            Join us on this journey as we capture the essence of life's most
            precious moments, one frame at a time.
          </h2>
        </div>
        <div className="img_div" data-aos="fade-left">
          <img src={c} alt="c_img" />
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px;

  .main_div {
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    .content_div {
      display: flex;
      flex-direction: column;
      justify-content: center;

      gap: 20px;
      flex: 1;

      img {
        width: 176px;
        height: 88px;
      }
    }

    .img_div {
      flex: 1;
      display: flex;
      justify-content: end;
      img {
        width: 541px;
        height: 672px;
      }
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;

    .main_div .img_div {
      flex: unset;
      margin-top: 40px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .main_div .img_div {
      flex: unset;
      width: 100%;
      margin-top:40px;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
`;
