import React, { useEffect } from "react";
import styled from "styled-components";
import product1 from "../Images/product1.png";
import product2 from "../Images/product2.png";
import product3 from "../Images/product3.png";
import product4 from "../Images/product4.png";
import Slider from "react-slick";
import Aos from "aos";

export default function Sec1() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>

      <div className="main_cont" data-aos="fade-up">
        <h1>Product Photography</h1>
        <h2 style={{ textAlign: "center" }}>
          At TimelessFilms.Media, where we bring products to life through
          captivating imagery and expertly crafted videos.
        </h2>
        <h2 style={{ textAlign: "center" }}>
          With our passion for creativity and attention to detail, we offer a
          comprehensive range of product photography and videography services
          designed to elevate your brand and showcase your products in the best
          possible light.
        </h2>
      </div>

      <div className="desk_div">
        <div className="main_images">
          <img src={product1} alt="product1" className="productone"   data-aos="zoom-in"/>

          <div className="inline_images">
            <img src={product2} alt="product2" />
            <img src={product3} alt="product3" />
            <img src={product4} alt="product4" />
          </div>
        </div>
      </div>

      <div className="mob_div">
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <img src={product1} alt="product1" />
            </div>

            <div>
              <img src={product2} alt="product2" />
            </div>

            <div>
              <img src={product3} alt="product3" />
            </div>

            <div>
              <img src={product4} alt="product4" />
            </div>
          </Slider>
        </div>
      </div>

    </Root>
  );
}

const Root = styled.section`
  padding: 120px 54px 40px;

  .main_cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    text-align: center;
  }

  .main_images {
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-top: 30px;

    .inline_images {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      img {
        width: 400px;
        height: 344px;
      }
    }
  }
  
  .mob_div{
    display:none;

    .slick-slide img {
    width: 100%;
}

.slick-track {
  display:flex;
  gap:20px;
}
  }

  @media (max-width: 567px) {
    /* padding: 40px 20px;

    .main_images .inline_images img {
      width: 100%;
    } */
      padding: 40px 20px;
    .desk_div {
      display: none;
    }

    .mob_div {
      display: block;
      margin: 30px 0;
      .slick-slide img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-radius: 20px;
      }
    }

  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 60px 20px;
    .main_images .inline_images img {
      width: 48%;
    }

    .mob_div{
    display:none;
  }

  }
`;
