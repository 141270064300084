import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import log from "../Images/log.png";
import tik from "../Images/tik.png";
import instag from "../Images/instag.png";
import linked from "../Images/linked.png";

function NavBarr() {
  return (
    <Root>
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container fluid>
          <Navbar.Brand href="#home">
            <a href="home">
              {" "}
              <img src={log} alt="log" className="main_logo" />{" "}
            </a>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav" className="nabspacing">
            
            <Nav>
              <Nav.Link href="home">HOME</Nav.Link>
              <Nav.Link href="fashion">FASHION</Nav.Link>
              <Nav.Link href="/">FILM PRODUCTION</Nav.Link>
              <Nav.Link href="/">COMMERCIAL</Nav.Link>
              <Nav.Link href="wedding">WEDDING</Nav.Link>
              <Nav.Link href="/">SHOWREEL</Nav.Link>
              <Nav.Link href="/food">Food</Nav.Link>
            </Nav>

            <div className="right_content">
              {/* <div className="contact_div">
                <img src={cal} alt="cal" />
                <a href="/">1810 111 0000</a>
              </div> */}
              <div className="social_icon">
                {/* <a href="/">
                  {" "}
                  <img src={fac} alt="facebook" />
                </a>

                <a href="/">
                  {" "}
                  <img src={twi} alt="twitter" />
                </a> */}

                <a href="/">
                  <img src={instag} alt="instag" />
                </a>

                <a href="/">
                  <img src={tik} alt="tiktok" />
                </a>

                <a href="/">
                  <img src={linked} alt="linked" />
                </a>
              </div>
              <div className="shoot_btn">
                <a href="tel:+1234567890">Call Us</a>
              </div>
            </div>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Root>
  );
}

export default NavBarr;

const Root = styled.section`
  width: 100%;

  .bg-body-tertiary {
    background-color: #ffffff !important;
  }

  a.nav-link {
    font-family: Bayon;
    font-size: 16px;
    font-weight: 400;
    line-height: 28.91px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #6e6e6e;
    border-right: 2px solid #585858;
    padding: 0 26px 0 0 !important;
  }

  a.nav-link:nth-child(7) {
    border-right: unset;
  }

  .navbar-expand-lg .navbar-nav {
    gap: 30px;
  }

  .nabspacing {
    justify-content: space-around;
  }

  .right_content {
    display: flex;
    gap: 20px;

    .contact_div {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        width: 13.5px;
        height: 13.5px;
      }
      a {
        font-family: Bayon;
        font-size: 16px;
        font-weight: 400;
        line-height: 28.91px;
        text-align: left;
        color: #292a36;
        text-decoration: none;
      }
    }

    .social_icon {
      gap: 10px;
      display: flex;
      img {
        width: 28px;
        height: 28px;
      }
    }
    .shoot_btn {
      a {
        border: 3.52px solid black;
        padding: 5px 16px;
        font-family: Bayon;
        font-size: 15px;
        font-weight: 400;
        line-height: 27.1px;
        letter-spacing: 0.02em;
        text-align: left;
        color: #000000;
        border-radius: 4.4px;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }

  @media (max-width: 567px) {

    img.main_logo {
      height: 90px;
    }

    .navbar-expand-lg .navbar-nav {
      margin-left: 20px;
      gap: 20px;
    }

    a.nav-link {
      border-right: unset;
    }

    .right_content {
      gap: 2px;
      margin: 20px 0 20px 0;
      justify-content: space-between;
    }

    .right_content .contact_div {
      gap: 5px;
    }

    .right_content .social_icon {
      gap: 5px;
    }

  }

  @media (min-width: 567px) and (max-width: 992px) {

    a.nav-link {
      border: unset;
    }
    .right_content {
      gap: 2px;
      margin: 20px 0 20px 35px;
      justify-content: space-between;
    }

    .navbar-expand-lg .navbar-nav {
      margin-left: 40px;
    }

  }
`;
