import React, { useEffect } from "react";
import styled from "styled-components";
import food5 from "../Images/food5.png";
import {
  PriHeader,
  Packagedesc,
  PlanButton,
  DiamondPlanButton,
} from "../Global";
import { GoCheckCircleFill } from "react-icons/go";
import Aos from "aos";

export default function Sec7() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 400,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="logo_head" data-aos="fade-up">
        <img src={food5} alt="food5" />
        <div className="content_div">
          <PriHeader>Our Packages</PriHeader>
          <Packagedesc>
            There are three carefully crafted service packages to assist most
            budgets. All packages have the same simple payment terms: 50% to
            book your slot and 50% required 10 days prior to the wedding. That’s
            it. Simply select a package and leave the rest to us!{" "}
          </Packagedesc>
        </div>
      </div>
      <div className="plan_div">
        <div className="sub_plan_div">
          <h3>Standard Package</h3>
          <p>
            Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
            elit.{" "}
          </p>
          <h4>299<sup>99</sup></h4>
          <div className="inclu_content">
            <h5>What’s included</h5>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>One Videographer</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>One Photographer</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>HD Quality</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Full Post Production</h5>
            </div>
          </div>

          <div className="plan_btn">
            <PlanButton>Get Started</PlanButton>
          </div>
        </div>

        <div className="sub_plan_div_bg">
          <h3>Diamond Package</h3>
          <p>
            Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
            elit.{" "}
          </p>
          <h4>599<sup>99</sup></h4>
          <div className="inclu_content">
            <h5>What’s included</h5>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Two Videographers</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>One Photographer</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>4K Quality</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Full Post Production</h5>
            </div>
          </div>

          <div className="plan_btn">
            <DiamondPlanButton>Get Started</DiamondPlanButton>
          </div>
        </div>

        <div className="sub_plan_div">
          <h3>Platinum Package</h3>
          <p>
            Lorem ipsum dolor sit amet doloroli sitiol conse ctetur adipiscing
            elit.{" "}
          </p>
          <h4>1399<sup>99</sup></h4>
          <div className="inclu_content">
            <h5>What’s included</h5>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Two Videographers</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Two Photographer</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>4k Cinema Camera Quality</h5>
            </div>
            <div className="icon_content">
              <GoCheckCircleFill />
              <h5>Full Post Production</h5>
            </div>
          </div>

          <div className="plan_btn">
            <PlanButton>Get Started</PlanButton>
          </div>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;

  .logo_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    .content_div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 0px 100px;
    }
  }

  .plan_div {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    padding: 0 40px;

    .sub_plan_div {
      flex: 1;
      border: 1px solid #dcdcdc;
      box-shadow: 0px 2px 12px 0px #14142b14;
      border-radius: 24px;
      padding: 50px 30px 40px;
      gap: 12px;
      display: flex;
      flex-direction: column;
      h3 {
        font-family: Bayon;
        font-size: 35px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        color: #252525;
      }
      h4 {
        font-family: Poppins;
        font-size: 54px;
        font-weight: 700;
        line-height: 66px;
        text-align: left;
        color: #252525;

        sup {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #6f6c90;
          top: -1.8em;
        }
      }
      p {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #6f6c90;
      }
      .inclu_content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        h5 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #252525;
        }
        .icon_content {
          display: flex;
          align-items: center;
          gap: 14px;
          h5 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #252525;
            margin: 0;
          }
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }

      .plan_btn {
        /* display:flex;
          justify-content:center; */
        margin-top: 20px;
        button.sc-aYaIB.frQtlG {
          width: 100%;
        }
      }
    }

    /* .bg{
    } */
    .sub_plan_div_bg {
      flex: 1;
      border: 1px solid #dcdcdc;
      box-shadow: 0px 2px 12px 0px #14142b14;
      border-radius: 24px;
      padding: 50px 30px 40px;
      gap: 12px;
      display: flex;
      flex-direction: column;
      background: linear-gradient(145.84deg, #150642 16.54%, #47193f 84.75%);
      h3 {
        font-family: Bayon;
        font-size: 35px;
        font-weight: 400;
        line-height: 35px;
        text-align: left;
        color: #ffffff;
      }
      h4 {
        font-family: Poppins;
        font-size: 54px;
        font-weight: 700;
        line-height: 66px;
        text-align: left;
        color: #ffffff;
        sup {
          font-family: Poppins;
          font-size: 20px;
          font-weight: 500;
          line-height: 22px;
          text-align: left;
          color: #6f6c90;
          top: -1.8em;
        }
      }
      p {
        font-family: DM Sans;
        font-size: 18px;
        font-weight: 400;
        line-height: 26px;
        text-align: left;
        color: #d9dbe9;
      }
      .inclu_content {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;

        h5 {
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          text-align: left;
          color: #ffffff;
        }
        .icon_content {
          display: flex;
          align-items: center;
          gap: 14px;
          h5 {
            font-family: Poppins;
            font-size: 18px;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
            color: #ffffff;
            margin: 0;
          }
          svg {
            width: 24px;
            height: 24px;
            color: #ffffff;
          }
        }
      }

      .plan_btn {
        /* display:flex;
          justify-content:center; */
        margin-top: 20px;
        button.sc-aYaIB.frQtlG {
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 567px) {
    padding: 0px 20px 20px;

  .logo_head{
  gap: 0px;
   .content_div {
    padding: 0;
    gap:20px;
}
}

.plan_div {
    padding:0;
    .sub_plan_div {
    width: 100%;
    .dQUMTm {
    width: 100%;
}
}
.sub_plan_div_bg {
    width: 100%;
    .dZXdbM {
    width: 100%;
    
    
}
}
}

.beimEb {
    line-height: unset;
}


.hDwbKq {
    width:100%;

  
}

.TRobD {
  width:100%;
}

  }

  @media (min-width: 567px) and (max-width: 992px) {
    padding: 0px 20px;
    .logo_head{
      gap: 0px;
   .content {
    padding: 0;
}
}

.plan_div {
    padding:0;
   

}

  }
`;
