import styled from "styled-components";

export const PriButton = styled.button`
  width:164px;
  height:50px;
  padding:16px 32px;
  border-radius:24px;
  color:#ffffff;
  border:none;
  background:linear-gradient(145.84deg, #150642 16.54%, #47193f 84.75%);
  font-size:16px;
  font-weight:400;
  line-height:18px;
`;

export const SeeMoreButton = styled.button`
  width: 80px;
  height: 24px;
  font-family: "Prompt";
  font-size: 16px;
  font-weight: 500 !important;
  line-height: 24.19px;
  color: #1a281f;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #252525;
  text-transform: uppercase;
  padding: 0px;
`;

export const SecButton = styled.button`
  border: 3px solid black;
  border-radius: 30px;
  width: 121px;
  height: 56px;
  background-color: transparent;
  color: #292a36;
  border: 3.52px solid;
  font-family: Bayon;
  font-size: 15px;
  font-weight: 400;
  line-height: 27.1px;
  letter-spacing: 0.02em;

  /* border-image-source: linear-gradient(
    145.84deg,
    #150642 16.54%,
    #47193f 84.75%
  ); */
`;

export const PlanButton = styled.button`
  width: 308px;
  height: 72px;
  border-radius: 96px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  border: none;
  background: linear-gradient(145.84deg, #150642 16.54%, #47193f 84.75%);
`;

export const DiamondPlanButton = styled.button`
  width: 308px;
  height: 72px;
  border-radius: 96px;
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  color: linear-gradient(145.84deg, #150642 16.54%, #47193f 84.75%);
  border: none;
  background: #ffffff;
`;

export const PriHeader = styled.header`
  font-family: "Bayon";
  font-size: 35px ;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #0b1026;
  @media (max-width: 567px){
    font-size: 25px;
    line-height: 30px;
  }
`;
export const PriText = styled.p`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 34px;
  text-align: left;
  margin: 0px !important;
  @media (max-width: 567px){
    font-size: 14px;
    line-height: 26px;
  }
`;
export const SpanText = styled.span`
  font-family:Poppins;
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  text-align: left;
  color: #06040a;
  @media (max-width: 567px){
    font-size: 14px;
    line-height: 26px;
  }
`;
export const LargeHeader = styled.h3`
  font-family: Bayon;
  font-size: 45px;
  font-weight: 400;
  line-height: 100px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #0b1026;
  text-transform: uppercase;
`;
export const Title = styled.label`
  font-family: Bayon;
  font-size: 22px;
  font-weight: 400;
  line-height: 39.75px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #1a281f;
  text-transform: uppercase;
`;

export const Input = styled.input`
  border: 0.5px solid #4e4e4e;
  height: 40px;
  border-radius: 3px;
  padding-left: 10px;
`;

export const TextHead = styled.text`
  font-family:Poppins;
  font-size:18px;
  font-weight:400;
  line-height:35px;
  text-align:left;
  @media (max-width: 567px){
    font-size: 14px;
    line-height: 26px;
  }
`;

export const Subhead = styled.text`
  font-family: Poppins;
  font-size: 25px;
  font-weight: 600;
  line-height: 45px;
  text-align: left;
  color: #333333;
  @media (max-width: 567px){
    line-height:25px;
    font-size:20px;
  }
`;

export const Packagedesc = styled.text`
  font-family: Poppins;
  font-size: 24px;
  font-weight: 400;
  line-height: 38px;
  text-align: center;
  color: #252525;
  @media (max-width: 567px){
    font-size: 14px;
    line-height: 26px;
  }
`;

export const Footerhead = styled.text`
  font-family: Bayon;
  font-size: 25px;
  font-weight: 400;
  line-height: 45.17px;
  letter-spacing: 0.02em;
  text-align: left;
  color: #333333;
`;

export const Copyright = styled.text`
  font-size: 20px;
  font-weight: 300;
  line-height: 30.24px;
  text-align: left;
  color: #333333;
`;

export const Contacthead = styled.text`
  font-family: Bayon;
  font-size: 35px;
  font-weight: 400;
  line-height: 101.17px;
  text-align: left;
  color: #252525;
`;

export const Contactsubhead = styled.text`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: center;
  color: #6f6c90;
`;

export const Contactinfo = styled.text`
  font-family: Poppins;
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #ffffff;
`;

export const Contactdesc = styled.text`
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #c9c9c9;
`;

export const Contactlink = styled.text`
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
`;
