import React, { useEffect } from "react";
import styled from "styled-components";
import food5 from "../Images/food5.png";
import fash5 from "../Images/fash5.png";
import { Subhead, PriHeader, TextHead } from "../Global";
import Aos from "aos";

export default function Sec2(){
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="logo_head" data-aos="fade-up">
        <img src={food5} alt="food5" />
        <PriHeader>Our Fashion Photography and Videography Services Include</PriHeader>
      </div>

      <div className="gourmet_content">
        <img src={fash5} alt="fash5" data-aos="fade-right"/>
        <div className="content_div" data-aos="fade-left">
          <Subhead>Editorial Photoshoots</Subhead>
          <TextHead>
          Whether you're a fashion designer, boutique owner, or model, our editorial photoshoots are designed to showcase your unique style and vision. From high-fashion editorials to lifestyle campaigns, we'll work closely with you to create stunning images that tell your story.

          </TextHead>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px   40px  32px;
  .hoIDUr {
    letter-spacing: 0;
}
  .logo_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
  }

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap:10px;
      flex-direction: column;
    
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;
    .kucZgf {
      font-size: 55px !important;
      line-height: unset;
    }
    .gourmet_content {
      flex-wrap: wrap;
      gap: 30px;
      img {
        width: 100%;
      }

      .iqFyfv {
        font-size: 31px;
        line-height: unset;
      }
    }

    .beimEb {
    line-height: unset;
}
  }

  @media (min-width: 567px) and (max-width: 992px){
  
    padding: 40px 20px;
    .kucZgf {
      font-size: 45px;
      line-height: unset;
    }
    .gourmet_content {
      flex-wrap: wrap;
      gap: 30px;
      img {
        width: 100%;
        height: 100%;
      }

      .iqFyfv {
        font-size: 32px;
        line-height: unset;
      }
    }

  }
`;
