import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes } from "react-router-dom";
import Home from "./Components/home/Home";
import Layout from "./Components/MainLayouts/Layout";
import Aboutus from "./Components/aboutus/Aboutus";
import Fashion from "./Components/fashion/Fashion"; 
import Food from "./Components/food/Food";
import Product from "./Components/product/Product";
import Wedding from "./Components/wedding/Wedding";
import Contact from "./Components/Contact/Contact";
import Portfolio from "./Components/home/Portfolio";

function App() {
  return (
    <div>
      <Layout>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/home" element={<Home/>}/>
          <Route path="/aboutus" element={<Aboutus/>}/>
          <Route path="/food" element={<Food/>}/>
          <Route path="/fashion" element={<Fashion/>}/>
          <Route path="/product" element={<Product/>}/>
          <Route path="/wedding" element={<Wedding/>}/>
          <Route path="/contact" element={<Contact/>}/>
          <Route path="/portfolio" element={<Portfolio/>}/>
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
