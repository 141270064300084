import React, { useEffect } from "react";
import styled from "styled-components";

import fash7 from "../Images/fash7.png";
import { Subhead, TextHead } from "../Global";
import Aos from "aos";

export default function Sec4() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>


      <div className="gourmet_content">
        <img src={fash7} alt="fash7"  data-aos="fade-right"/>
        <div className="content_div" data-aos="fade-left">
          <Subhead>Runway Coverage</Subhead>
          <TextHead>
          Capture the excitement and energy of your fashion events with our expert runway coverage services. From fashion weeks to boutique shows, we'll document every moment with precision and style, ensuring that your designs shine on the catwalk.
          </TextHead>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;
  border-top: 0.5px solid #292A36;

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap:10px;
      flex-direction: column;
    
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;
    .gourmet_content {
    flex-wrap: wrap;
    margin-top: 0px;
    gap: 30px;
    img {
    width: 100%;
}
  }
  .iqFyfv {
    font-size: 32px;
    line-height: unset;
}

.beimEb {
    line-height: unset;
}


  }

  @media (min-width: 567px) and (max-width: 992px){
  
  padding: 40px 20px;
  .kucZgf {
    font-size: 45px;
    line-height: unset;
  }
  .gourmet_content {
    flex-wrap: wrap;
    gap: 30px;
    img {
      width: 100%;
      height: 100%;
    }

    .iqFyfv {
      font-size: 32px;
      line-height: unset;
    }
  }

}
`;
