import React from "react";
import styled from "styled-components";
import logo from "../Images/logo.png";
import tik from "../Images/tik.png";
import instag from "../Images/instag.png";
import linked from "../Images/linked.png";
import { Copyright, Footerhead } from "../Global";

function Footer() {
  return (
    <Foot>
      <div className="main_div">
        <div className="sub_div_one">
          <div className="logo_div">
            <a href="home">
              <img src={logo} alt="logo_img" />
            </a>
            <div className="social_links">

              <a href="/">
                <img src={instag} alt="instag" />          
                    </a>

              <a href="/">
              <img src={tik} alt="tiktok" />
              </a>

              <a href="/">
              <img src={linked} alt="linked" />
              </a>

            </div>
          </div>
        </div>
        <div className="sub_div_two">
          <div className="mail_cont_div">
            <div className="const_sub_div">
              <Footerhead>For any inquiries please email</Footerhead>
              <a href="mailto:timelessfilms.media@outlook.com">
                timelessfilms.media@outlook.com
              </a>
            </div>

            {/* <div className="const_sub_div">
              <Footerhead>Contact Number</Footerhead>
              <a href="tel:+15559916296">+1-555-991-6296</a>
            </div> */}
          </div>
        </div>
        <div className="sub_div_three">
          <div className="menu_div">
            <Footerhead>Menu</Footerhead>
            <a href="home">Home </a>
            <a href="aboutus" >About us </a>
            <a href="/">Services </a>
            <a href="/">Privacy Poilcy </a>
            <a href="contact" >Contact</a>
            <a href="/" >Showreel</a>
          </div>
        </div>
        <div className="sub_div_four">
          <div className="menu_div">
            <Footerhead>Services</Footerhead>
            <a href="fashion" >Fashion </a>
            <a href="wedding" >Wedding </a>
            <a href="product" >Products </a>
            <a href="food" >Food </a>
          </div>
        </div>
      </div>
      <div className="copyright_div">
        <Copyright>Copyright © 2024, All Right Reserved.</Copyright>
      </div>
    </Foot>
  );
}

export default Footer;
const Foot = styled.section`
  .main_div {
    border-top: 0.5px solid #cccccc;
    width: 100%;
    display: flex;
    padding: 70px 60px;

    .sub_div_one {
      width: 35%;
      .logo_div {
        display: flex;
        flex-direction: column;
        gap: 40px;
        img {
          width: 91px;
          height: 100px;
        }
        .social_links {
          display: flex;
          gap: 25px;
          img {
            width: 35px;
            height: 35px;
          }
        }
      }
      /* .mail_cont_div {
        display: flex;
        gap: 30px;
        flex-direction: column;
        padding-left: 120px;
        .const_sub_div {
          display: flex;
          gap: 10px;
          flex-direction: column;
          a {
            font-size: 20px;
            font-weight: 400;
            line-height: 37px;
            text-align: left;
            color: #333333;
            text-decoration: none;
          }
        }
      }
      .menu_div {
        padding-left: 120px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.3px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #3f3f3f;
          text-decoration: none;
        }
      } */
    }

    .sub_div_two {
      width: 35%;
      .mail_cont_div {
        display: flex;
        gap: 30px;
        flex-direction: column;
        .const_sub_div {
          display: flex;
          gap: 10px;
          flex-direction: column;
          a {
            font-size: 20px;
            font-weight: 400;
            line-height: 37px;
            text-align: left;
            color: #333333;
            text-decoration: none;
          }
        }
      }
    }

    .sub_div_three {
      width: 15%;
      .menu_div {
        display: flex;
        flex-direction: column;
        gap: 15px;
        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.3px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #3f3f3f;
          text-decoration: none;
        }
      }
    }

    .sub_div_four {
      width: 15%;
      padding-left: 60px;
      .menu_div {
        display: flex;
        flex-direction: column;
        gap: 15px;
        a {
          font-size: 20px;
          font-weight: 400;
          line-height: 24.3px;
          letter-spacing: 0.02em;
          text-align: left;
          color: #3f3f3f;
          text-decoration: none;
        }
      }
    }
  }
  .copyright_div {
    border-top: 0.5px solid #cccccc;
    display: flex;
    justify-content: center;
    margin: 0 50px;
    padding: 20px 0 50px;
  }

  @media (max-width: 567px) {
    .main_div {
      padding: 40px 20px;
      flex-wrap: wrap;
      gap: 30px;
      .sub_div_one {
        width: 100%;
        .logo_div {
          align-items: center;
        }
      }

      .sub_div_two {
        width: 100%;
        .mail_cont_div {
          align-items: center;
        }
      }

      .sub_div_three {
        width: 45%;
        .menu_div {
          align-items: center;
        }
      }
      .sub_div_four {
        width: 45%;
        padding-left: unset;
        .menu_div {
          align-items: center;
        }
      }
    }
    .copyright_div {
      margin: 0 10px;
    }
  }

  
  @media (min-width: 567px) and (max-width: 992px){
  .main_div {
    padding: 70px 20px;
    gap: 30px;
    .sub_div_one .logo_div .social_links {
    display: flex;
    gap: 15px;
}
 .sub_div_two .mail_cont_div .const_sub_div a {
    word-break: break-all;
}

 .sub_div_four {
    padding-left: unset;
}
}
.jwgKVs {
    font-size: 20px;
  
}
  }
`;
