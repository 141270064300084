import React from "react";
import styled from "styled-components";
import b from "../Images/b.png";

export default function Sec1() {
  return (
    <Root>
      <div className="main_div">
        <div className="img_div" data-aos="fade-left">
          <img src={b} alt="b_img"/>
        </div>
        <div className="content_div" data-aos="fade-right">
          <h2>
            Our journey began with an education rooted in the heart of artistic
            innovation – Paris, where our team honed their craft in film
            production, mastering the intricacies of visual storytelling.
          </h2>
          <h2>
            Over the years, we've had the privilege of working on diverse
            projects that have taken us around the globe. <br/>From collaborating
            with Netflix in Dubai for the production of 'Bling' to contributing
            our talents to the blockbuster Bollywood movie 'Animal,' our
            portfolio reflects our commitment to excellence in every frame.
          </h2>
        </div>
      </div>
    </Root>
  );
}

const Root = styled.section`
padding: 40px 54px;
  .main_div {
    display:flex;
    flex-wrap:wrap;
    gap:70px;
    .img_div{
      display:flex;
      flex:1;
      img {
        width: 600px;
        height: 761px;
      }
    }

    .content_div {
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      flex:1;
    }
  }

  
  @media (max-width: 567px) {
    padding: 40px 20px;

  .main_div {
    gap:30px;

    .img_div {
    display: flex;
    flex: unset; 
    img{
      width:100%;
      height:100%;
    }
}


  }

  }

  @media (min-width: 567px) and (max-width: 992px){
   .main_div .img_div {
   flex: unset; 
    width: 100%;
}
.main_div .img_div {
    flex: unset;
    width: 100%;
    img{
      width: 100%;
      height:100%;
    }
}

  }
`;
