import React from 'react'
import Sec1 from './Sec1'
import Sec2 from './Sec2'
import Sec3 from './Sec3'
import Sec4 from './Sec4'
import Sec5 from './Sec5'
import Sec6 from './Sec6'
import Sec7 from './Sec7'
import Sec4Camera from '../home/Sec4Camera'

function Fashionphotography() {
  return (
    <div>
    <Sec1/>
    <Sec2/>
    <Sec3/>
    <Sec4/>
    <Sec5/>
    <Sec6/>
    <Sec7/>
    <Sec4Camera/>

    </div>
  )
}

export default Fashionphotography
