import React, { useEffect } from "react";
import styled from "styled-components";
import floral from "../Images/Floral design.png";
import couple from "../Images/sec31.png";
import { PriButton, PriHeader, SeeMoreButton, Title } from "../Global";
import watch from "../Images/watch.png";
import purse from "../Images/purse.png";
import bride from "../Images/bride.png";
import wwatch from "../Images/wwatch.png";
import flowergirl from "../Images/flowergirl.png";
import ccouple from "../Images/ccouple.png";
import Slider from "react-slick";
import Aos from "aos";

function Sec3() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  var settings = {
    dots:true,
    infinite:true,
    slidesToShow:3,
    slidesToScroll:3,
    initialSlide:0,
    autoplay:true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>
      <div className="heading" data-aos="fade-up">
        <img src={floral} alt="img" />
        <PriHeader>Our Work</PriHeader>
      </div>

      <div className="desk_div">
        <div className="content_div">
          <div className="first" >
            <div className="first_one" data-aos="zoom-in-down">
              <img src={couple} alt="img" />

              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery shoot i </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>

            <div className="first_one" data-aos="zoom-in-down">
              <img src={watch} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery shoot iii </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>
          </div>
          <div className="sec">
            <div className="sec_one" data-aos="zoom-in-down">
              <img src={purse} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery shoot ii </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>

            <div className="sec_one" data-aos="zoom-in-down">
              <img src={bride} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery wedding i </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>

            <div className="sec_one" data-aos="zoom-in-down">
              <img src={wwatch} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery shoot ii </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>
          </div>
          <div className="first">
            <div className="first_one" data-aos="zoom-in-down">
              <img src={flowergirl} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery wedding iii </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>

            <div className="first_one" data-aos="zoom-in-down">
              <img src={ccouple} alt="img" />
              <div
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Title>gallery shoot i </Title>
                <SeeMoreButton>See More</SeeMoreButton>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="mob_div">
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <div className="first_one" data-aos="zoom-in-down">
                <img src={couple} alt="img" />

                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery shoot i</Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>



            <div>
              <div className="sec_one" data-aos="zoom-in-down">
                <img src={purse} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery shoot ii </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>

            <div>
              <div className="sec_one" data-aos="zoom-in-down">
                <img src={wwatch} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery shoot ii </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>

            <div>
              <div className="first_one" data-aos="zoom-in-down">
                <img src={watch} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery shoot iii </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>


            <div>
              <div className="sec_one" data-aos="zoom-in-down">
                <img src={bride} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery wedding i </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>

            <div>
              <div className="first_one" data-aos="zoom-in-down">
                <img src={flowergirl} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery wedding iii </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>

            <div>
              {" "}
              <div className="first_one" data-aos="zoom-in-down">
                <img src={ccouple} alt="img" />
                <div
                  style={{
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Title>gallery shoot i </Title>
                  <SeeMoreButton>See More</SeeMoreButton>
                </div>
              </div>
            </div>

          </Slider>
        </div>
      </div>

      <div className="connect_btn ">
        <PriButton style={{ width: "200px", margin: "40px 0px" }}>
          Connect With Us
        </PriButton>
      </div>
    </Root>
  );
}

export default Sec3;
const Root = styled.section`
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  .heading {
    text-align: center;
    img {
      margin: 10px 0px;
    }
  }
  .content_div {
    display: flex;
    padding: 0px 40px;
    gap: 25px;
    margin-top: 30px;

    .first,
    .sec {
      display: flex;
      flex-direction: column;
      gap: 25px;
      flex: 1;
      justify-content: space-between;
    }
    .first_one,
    .sec_one {
      display: flex;
      flex-direction: column;
    }
  }
  .connect_btn {
    display: flex;
    justify-content: center;
  }

  
  .mob_div{
    padding:0 40px;
    display:none;



    .slick-slide img {
    width: 100%;
    height: 332px;
    object-fit: contain;
}

.slick-track {
  display:flex;
  gap:20px;
}
  }

  @media (max-width: 567px) {
    /* .content_div {
      padding: 0;
      flex-wrap: wrap;
      .first {
        flex: unset;
        width: 100%;
      }

      .sec {
        flex: unset;
        width: 100%;
      }
    } */
       margin:0;
    .desk_div{
        display:none;
      }

  .mob_div{
    padding:0 20px;
    display:block;
    margin:30px 0;
  }

    }

  @media (min-width: 567px) and (max-width: 992px) {
    .content_div {
      flex-wrap: wrap;
      padding: 0;
    }

    .content_div .first img,
    .content_div .sec img {
      /* flex-direction:unset;
    gap:25px;
    flex:1; */
      width: 100%;
    }

    .mob_div{
    display:none;
  }


  }
`;
