import React, { useEffect } from "react";
import styled from "styled-components";
import floral from "../Images/Floral design.png";
import { PriHeader, TextHead } from "../Global";
import { PriText } from "../Global";
import dubai from "../Images/dubai.png";
import one from "../Images/one.png";
import two from "../Images/two.png";
import three from "../Images/three.png";
import Slider from "react-slick";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AOS from "aos";
import "aos/dist/aos.css";

function Sec5() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Root>
      <div className="heading" >
        <img src={floral} alt="img" />
        <PriHeader data-aos="fade-up">Our Content Featured on Netflix</PriHeader>
      </div>
      <div className="body_div">
        <div className="desk_div">
          <div className="side_img" data-aos="fade-right">
            <div className="img_div">
              <img src={dubai} alt="img" />
            </div>
            <div className="img_div">
              <img src={one} alt="img" />
              <img src={two} alt="img" />
              <img src={three} alt="img" />
            </div>
          </div>
        </div>
        <div className="text_div_main">
          <div className="desc_content" data-aos="fade-left">
            <TextHead>
              {" "}
              We are thrilled to share that our exclusive content was featured
              on the hit <b>Netflix </b>show, "<b>Dubai Bling.</b>"
            </TextHead>
            <PriText
              style={{ paddingRight: "20px", textAlign: "left", margin: "0px" }}
            >
              The glamorous series, known for its captivating portrayal of
              opulent lifestyles & vibrant personalities, chose London as a
              backdrop for some of its most exciting scenes.
              <PriText>
                We had the honor of being part of this spectacular production,
                particularly in the episodes featuring the charismatic Farhana
                Bodi.
              </PriText>
            </PriText>

            <PriText
              style={{ paddingRight: "20px", textAlign: "left", margin: "0px" }}
            >
              Our collaboration brought a unique blend of London's chic
              sophistication & Dubai's dazzling allure to the screen.
              <PriText>
                Dive into our content to experience the behind-the-scenes magic
                and explore the luxurious world of "Dubai Bling."
              </PriText>
            </PriText>
          </div>
          <div className="mob_acc" data-aos="fade-left">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography>
                  <TextHead>
                    {" "}
                    We are thrilled to share that our exclusive content was
                    featured on the hit <b>Netflix </b>show, "
                    <b>Dubai Bling.</b>"
                  </TextHead>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <PriText
                    style={{
                      paddingRight: "20px",
                      textAlign: "left",
                      margin: "0px",
                    }}
                  >
                    The glamorous series, known for its captivating portrayal of
                    opulent lifestyles & vibrant personalities, chose London as
                    a backdrop for some of its most exciting scenes.
                    <PriText>
                      We had the honor of being part of this spectacular
                      production, particularly in the episodes featuring the
                      charismatic Farhana Bodi.
                    </PriText>
                  </PriText>
                  <br />
                  <PriText
                    style={{
                      paddingRight: "20px",
                      textAlign: "left",
                      margin: "0px",
                    }}
                  >
                    Our collaboration brought a unique blend of London's chic
                    sophistication & Dubai's dazzling allure to the screen.
                    <PriText>
                      Dive into our content to experience the behind-the-scenes
                      magic and explore the luxurious world of "Dubai Bling."
                    </PriText>
                  </PriText>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </div>

      <div className="mob_div" data-aos="fade-right">
        <div className="slider-container">
          <Slider {...settings}>
            <div>
              <div className="img_div">
                <img src={dubai} alt="img" />
              </div>
            </div>
            <div className="img_div">
              <img src={one} alt="img" />
            </div>

            <div>
              <div className="img_div">
                <img src={two} alt="img" />
              </div>
            </div>

            <div>
              <div className="img_div">
                <img src={three} alt="img" />
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </Root>
  );
}

export default Sec5;
const Root = styled.section`
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0px 10px;
  .heading {
    text-align: center;
    img {
      margin: 10px 0px;
    }
  }
  .body_div {
    display: flex;
    margin: 40px 0px;
    padding: 0 30px;
    gap: 35px;
    flex: 1;
  }
  .text_div_main,
  .side_img {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .text_div_main {
    gap: 20px;
  }
  .img_div {
    display: flex;
    gap: 20px;
  }

  .mob_div {
    padding: 0 40px;
    display: none;

    .slick-slide img {
      width: 100%;
    }

    .slick-track {
      display: flex;
      gap: 20px;
    }
  }
  .mob_acc {
    display: none;
  }

  @media (max-width: 567px) {
    .kucZgf {
      line-height: unset;
    }

    .body_div {
      flex-wrap: wrap;
      gap: 26px;
      padding: 10px;
    }

    .desk_div {
      display: none;
    }

    /* .body_div .side_img .img_div img{
  width:100%;
 }

 .img_div {
    display:flex;
    gap:20px;
    flex-wrap:wrap;
} */

    .desk_div {
      display: none;
    }

    .mob_div {
      padding: 0 20px;
      display: block;
      margin: 30px 0;
    }
    .desc_content {
      display: none;
    }
    .mob_acc {
      display: block;
    }
  }

  @media (min-width: 567px) and (max-width: 992px) {
    .body_div {
      flex-wrap: wrap;
      gap: 26px;
      padding:unset;
      justify-content: center;
    }

    .mob_div {
      display: none;
    }

    .mob_acc {
      display: none;
    }
  }
`;
