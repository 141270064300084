import React, { useEffect } from "react";
import styled from "styled-components";

import fash9 from "../Images/fash9.png";
import { Subhead, TextHead } from "../Global";
import Aos from "aos";

export default function Sec5() {
  useEffect(() => {
    Aos.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <Root>
      <div className="gourmet_content">
        <div className="content_div" data-aos="fade-left">
          <Subhead>Fashion Videos and Campaigns</Subhead>
          <TextHead>
          Bring your fashion brand to life with dynamic video content that engages and inspires. Whether you need promotional videos, behind-the-scenes footage, or social media campaigns, we'll create videos that showcase your brand's personality and captivate your audience.
          </TextHead>
        </div>
        <img src={fash9} alt="fash9" data-aos="fade-right"/>
      </div>
    </Root>
  );
}

const Root = styled.section`
  padding: 40px 54px 75px;
  border-top: 0.5px solid #292a36;

  .gourmet_content {
    display: flex;
    gap: 80px;
    margin-top: 40px;
    img {
      width: 485px;
      height: 350px;
    }
    .content_div {
      display: flex;
      gap: 10px;
      flex-direction: column;
    }
  }

  @media (max-width: 567px) {
    padding: 40px 20px;
    .gourmet_content {
    flex-wrap: wrap;
    margin-top: 0px;
    gap: 30px;
    img {
    width: 100%;
}
  }
  .iqFyfv {
    font-size: 32px;
    line-height: unset;
}
.beimEb {
    line-height: unset;
}

  
  }
    
  @media (min-width: 567px) and (max-width: 992px){
  
  padding: 40px 20px;
  .kucZgf {
    font-size: 45px;
    line-height: unset;
  }
  .gourmet_content {
    flex-wrap: wrap;
    gap: 30px;
    margin-top:0;
    img {
      width: 100%;
      height: 100%;
    }

    .iqFyfv {
      font-size: 32px;
      line-height: unset;
    }
  }

}
`;
